
import { defineComponent, inject, onMounted, ref, nextTick} from "vue";
import { useRouter, useRoute } from "vue-router";
import $ from "jquery";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import Modal from "@/components/UI/Modal";
import Loading from "@/components/UI/Loading/src/Loading.vue";
export default defineComponent({
  name: "yearVoteView",
  components: {
    Loading
  },
  setup() {
    const voteListA = ref<object>([])
    const router = useRouter();
    //  展示用户信息
    const userInfo = inject("userInfo") as any;

    const loading = ref(false);
    const loadOption = {
      text: "正在加载...",
      color: "#df0024",
      textColor: "#df0024",
    };

    onMounted(function() {
      // $('.home-wapper').css('padding-top', '0')
      // const height = Number($(window).height()) - Number($('header').outerHeight())-Number($('main').outerHeight()) + 'px'
      // $('.vote-list').css({'height': height})

      const token = localStorage.getItem("token");
      if (!token) {
          Modal({
              title: "温馨提示",
              content: "登录后才可以进行投票！",
              onClose: () => {
                  router.push({
                      name: "Login",
                      query: {
                          redirect: router.currentRoute.value.fullPath,
                      },
                  });
              },
          });
          return false;
      }

      loading.value = true;
      axios
      .get("/M/Staff/votingWyStatistics")
      .then(res => {
        loading.value = false;
        if (res.data.success) {
          const data = res.data.obj
          voteListA.value = data.staffList13
          nextTick(() => {
            $('.home-wapper').css('padding-top', '0')
            const height = Number($(window).height()) - Number($('header').outerHeight())-Number($('main').outerHeight()) + 'px'
            $('.vote-list').css({'height': height})
          })
        } else {
          Toast({
            type: "error",
            title: res.data.msg,
            onClose: () => {
                router.push({
                  name: "yearVote"
                });
              },
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
    })

    function refreshVote() {
      window.location.reload()
    }


    function back() {
      router.back();
    }

    return {
      userInfo,
      voteListA,
      loading,
      loadOption,
      back,
      refreshVote
    }
  },
});
